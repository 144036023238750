<template>
	<div style="--aspect-ratio: 4" class="bg-img my-1 hovered">
		<div class="aspect-ratio-box-inside">
			<a :href="link">
				<div class="row h-100 align-items-center">
					<div class="col">
						<p class="btn-text">{{ title }}</p>
					</div>
					<div class="col text-right pr-5">
						<Arrow width="40" :link="link"></Arrow>
					</div>
				</div>
			</a>
		</div>
	</div>
</template>

<script>
import Arrow from '@/components/Arrow.vue';
export default {
	components: {
		Arrow,
	},
	props: {
		title: { type: String, default: 'ervaringen' },
		content: { type: String },
		bgColor: { type: String },
		image: { type: String, default: '/uploads/robin2.jpg' },
		link: { type: String, default: '/referenties' },
		aspect: { type: String, default: '0.8' },
	},
};
</script>

<style scoped>
.bg-img {
	background-image: url('../assets/bg-references.png');
	background-repeat: no-repeat;
	background-size: cover;
}

.btn-text {
	text-align: left;
	margin: 0;
	padding: 0 0 0 20px;
	color: white;
	font-size: 20px;
	text-transform: uppercase;
}
</style>
