<template>
	<a :href="link">
		<img src="@/assets/pijl.png" class="arrow" v-bind:style="{width: width +'px'}" />
	</a>
</template>

<script>
export default {
	data() {
		return {};
	},

	props: {
		width: { type: String, default: '30' },
		link: { type: String, default: '' }
	}
};
</script>

<style scoped>
.arrow {
	cursor: pointer;
	/* margin: 0 8px; */
}
</style>
