<template>
	<div class="text-center">
		<div class="jumbotron jumbotron-fluid pt-1 pb-0">
			<div class="row mr-0">
				<!-- left column -->
				<div class="col bg-pink">
					<div class="container has-width-50 float-right">
						<div class="row">
							<div class="col pr-large">
								<a :href="link_left">
									<div class="valign-parent">
										<div class="valign-child">
											<h1 class="banner-text mt-2">{{ title_left }}</h1>
										</div>
										<div class="valign-child pl-2">
											<Arrow v-if="!showText" :link="link_left"></Arrow>
										</div>
									</div>
									<div v-if="showText">
										<p class="banner-text">
											{{ content_left }}
										</p>
										<div class="valign-parent">
											<div class="valign-child">
												<p class="banner-text">{{ link_text_left }}</p>
											</div>
											<div class="valign-child">
												<Arrow :link="link_left"></Arrow>
											</div>
										</div>
										<br />
									</div>
								</a>
							</div>
						</div>
					</div>
				</div>

				<!-- right column -->
				<div class="col bg-red">
					<div class="container has-width-50 float-md-left">
						<div class="row">
							<div class="col pl-large">
								<a :href="link_right">
									<div class="valign-parent">
										<div class="valign-child">
											<h1 class="banner-text mt-2">{{ title_right }}</h1>
										</div>
										<div class="valign-child pl-2">
											<Arrow v-if="!showText" :link="link_right"></Arrow>
										</div>
									</div>
									<div v-if="showText">
										<p class="banner-text">
											{{ content_right }}
										</p>
										<div class="valign-parent">
											<div class="valign-child">
												<p class="banner-text">{{ link_text_right }}</p>
											</div>
											<div class="valign-child">
												<Arrow :link="link_right"></Arrow>
											</div>
										</div>
									</div>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- logo button in the middle -->
		<router-link to="/simone" class="d-none d-sm-none d-md-block">
			<img
				src="@/assets/logo-knop.png"
				class="logo-knop-small float-right"
				v-bind:class="{ 'logo-knop-large': showText }"
			/>
		</router-link>
	</div>
</template>

<script>
import Arrow from '@/components/Arrow.vue';

export default {
	data() {
		return {
			styleObject: {
				top: '362px',
				marginLeft: '-50px',
				maxWidth: '86px',
			},
		};
	},
	components: {
		Arrow,
	},
	props: {
		showText: { type: Boolean },
		title_left: { type: String },
		link_text_left: { type: String },
		link_left: { type: String },
		content_left: { type: String },
		title_right: { type: String },
		link_text_right: { type: String },
		link_right: { type: String },
		content_right: { type: String },
	},
	created: function () {
		// console.clear();
		// console.log(Date.now());
		// console.log(this.showText, this.title_left);
	},
};
</script>


<style scoped>
.banner-text {
	color: white;
	text-align: left;
	padding-right: 5px;
}

.logo-knop-small {
	position: absolute;
	top: 373px;
	margin-left: -50px;
	max-width: 86px;
	z-index: 9;
}

.logo-knop-large {
	position: absolute;
	top: 373px;
	margin-left: -117px;
	max-width: 220px;
	z-index: 9;
}

.has-width-50 {
	max-width: 570px !important;
}

@media (min-width: 768px) {
	.pr-large {
		padding-right: 90px;
	}
}

@media (min-width: 768px) {
	.pr-large {
		padding-right: 120px;
	}
	.pl-large {
		padding-left: 120px;
	}
}

.valign-parent {
	display: table;
}
.valign-child {
	display: table-cell;
	vertical-align: middle;
}
</style>
